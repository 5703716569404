import React, { useEffect, useState } from "react";
import "./rugs-order-form.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import OrderListTable from "../../components/rugs-orders/rugs-orders-table";

const RugsOrderForm = () => {

  return (
    <div className="list">
      <Sidebar />
      <div className="listContainer">
        <Navbar />
        <OrderListTable />
      </div>
    </div>
  );
};

export default RugsOrderForm;
