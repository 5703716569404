import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import { Link, useNavigate } from 'react-router-dom';
import Axios from '../../api'; // Assuming this is your Axios instance
import { toast } from "react-toastify";
import shadows from '@mui/material/styles/shadows';

const OrderListTable = () => {
    const [tableData, setTableData] = useState([]);
    const navigate = useNavigate();

    const fetchOrders = async () => {
        try {
            const response = await Axios.get('/api/orders/admin');
            const formateUser = response.data.map((order) => {
                return {
                    ...order,
                    user: `${order.shippingInfo.name} - ${order.shippingInfo.email}`
                }
            });
            setTableData(formateUser);
        } catch (error) {
            console.log(error);
            toast.error('Error fetching orders');
        }
    };

    useEffect(() => {
        fetchOrders();
    }, []);

    const handleDelete = async (id) => {
        try {
            await Axios.delete(`/api/orders/${id}`); // Adjust endpoint for deleting orders
            setTableData(prevData => prevData.filter(order => order._id !== id));
            toast.success('Order deleted successfully');
        } catch (error) {
            console.error(error);
            toast.error('Error deleting order');
        }
    };

    const columns = [
        { field: 'user', headerName: 'User', flex: 1, minWidth: 200 },
        { field: 'totalPrice', headerName: 'Total Price', flex: 1, minWidth: 150 },
        { field: 'shippingInfo', headerName: 'Shipping Info', flex: 2, minWidth: 300, valueGetter: (params) => `${params.row.shippingInfo.streetAddress}, ${params.row.shippingInfo.city}` },
        { field: 'status', headerName: 'Status', flex: 1, minWidth: 150 },
        { field: 'createdAt', headerName: 'Created At', flex: 1, minWidth: 200, type: 'date' },
        {
            field: 'action',
            headerName: 'Action',
            flex: 1,
            minWidth: 200,
            renderCell: (params) => (
                <div className="cellAction">
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => handleDelete(params.row._id)}
                    >
                        Delete
                    </Button>
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => navigate(`/orders/edit/${params.row._id}`)}
                    >
                        Edit
                    </Button>
                </div>
            ),
        },
    ];

    return (
        <div className="datatable">
            <div className="datatableTitle">
                <Link to={`/orders/add`} className="link">
                    Add New Order
                </Link>
            </div>
            <DataGrid
                rows={tableData}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                getRowId={(row) => row._id}
                checkboxSelection
            />
        </div>
    );
};

export default OrderListTable;
