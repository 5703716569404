import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import { Link, useNavigate } from 'react-router-dom';
import Axios from '../../api';
import { toast } from "react-toastify";

const RugsProductTable = ({ data, fetchData }) => {
    const [tableData, setTableData] = useState([]);
    const navigate = useNavigate();

    const handleDelete = async (id) => {
        try {
            await Axios.delete(`/api/products/${id}`);
            let updatedData = data?.filter((item) => item?._id !== id);
            setTableData(updatedData);
            fetchData();
            toast.success('Product deleted successfully');
        } catch (error) {
            console.error(error);
            toast.error('Error deleting product');
        }
    };

    const columns = [
        { field: 'name', headerName: 'Name', flex: 1, minWidth: 200 },
        { field: 'details', headerName: 'Details', flex: 2, minWidth: 300 },
        { field: 'price', headerName: 'Price', flex: 1, minWidth: 150 },
        { field: '_updatedAt', headerName: 'Updated At', flex: 1, minWidth: 200, type: 'date' },
        {
            field: 'action',
            headerName: 'Action',
            flex: 1,
            minWidth: 200,
            renderCell: (params) => (
                <div className="cellAction">
                    <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => handleDelete(params?.row?._id)}
                    >
                        Delete
                    </Button>
                    <Button variant="outlined"
                        color="secondary" onClick={() => navigate(`/rugs/edit/${params?.row?._id}`)} >
                        Edit
                    </Button>
                </div>
            ),
        },
    ];

    useEffect(() => {
        setTableData(data);
    }, [data]);

    return (
        <div className="datatable">
            <div className="datatableTitle">
                <Link to={`/rugs/add`} className="link">
                    Add New Product
                </Link>
            </div>
            <DataGrid
                rows={tableData}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                getRowId={(row) => row?._id}
                checkboxSelection
            />
        </div>
    );
};

export default RugsProductTable;
