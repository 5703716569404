import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import List from "./pages/list/List";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { hotelInputs, roomInputs, userInputs } from "./formSource";
import "./style/dark.scss";
import { useContext } from "react";
import { DarkModeContext } from "./context/darkModeContext";
import CheckAuth from "./components/CheckAuth/CheckAuth";
import { hotelColumns } from "./datatablesource";
import NewHotel from "./pages/newHotel/NewHotel";
import NewHero from "./pages/newHero/NewHero";
import Enquiry from "./pages/enquiry-list/enquiry";
import Bespoke from "./pages/bespoke-list/bespokedetails";
import CountryData from "./pages/country-list/country-list";
import AddCountry from "./pages/country-list/country-page";
import CountryEditPage from "./components/country-data/country-edit";
import FooterManagement from "./components/footer/footer";
import Itinerary from "./pages/itinerary-table/itinerary";
import TravelPackageForm from "./components/travelpackage/travel-package";
import TravelEditPackageForm from "./components/travelpackage/travel-package-edit";
import EnquiryForm from "./pages/enquiry-form/enquiry-Form";
import TripItinerary from "./components/tripItinerary/tripItinerary";
import ItineraryTableTrip from "./pages/itineraryTrip/itineraryTrip";
import EditTripItinerary from "./components/tripItinerary/tripItinerary-edit";
import RugsForm from "./pages/rugs-form/rugs-Form";
import ProductForm from "./components/rugs/rugs-package";
import RugsEditForm from "./components/rugs/rugs-edit";
import RugsOrderForm from "./pages/rugs-order-form/rugs-order-Form";

function App() {
  const { darkMode } = useContext(DarkModeContext);

  return (
    <div className={darkMode ? "app dark" : "app"}>
      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route
              index
              element={
                <CheckAuth>
                  <Home />
                </CheckAuth>
              }
            />
            <Route path="login" element={<Login />} />

            <Route path="products">
              <Route
                index
                element={
                  <CheckAuth>
                    <List columns={hotelColumns} />
                  </CheckAuth>
                }
              />


              <Route
                path="new"
                element={
                  <CheckAuth>
                    <NewHotel inputs={hotelInputs} title="Add New Product" />
                  </CheckAuth>
                }
              />
              <Route
                path="new-hero"
                element={
                  <CheckAuth>
                    <NewHero inputs={hotelInputs} title="Add New Hero Images" />
                  </CheckAuth>
                }
              />
            </Route>

            <Route path="country">
              <Route
                index
                element={
                  <CheckAuth>
                    <CountryData columns={hotelColumns} />
                  </CheckAuth>
                }
              />

              <Route
                path="addcountry"
                element={
                  <CheckAuth>
                    <AddCountry />
                  </CheckAuth>
                }
              />

              <Route
                path="editcountry/:countryId"
                element={
                  <CheckAuth>
                    <CountryEditPage />
                  </CheckAuth>
                }
              />
            </Route>

            <Route
              path="/enquiry"
              element={
                <CheckAuth>
                  <Enquiry columns={hotelColumns} />
                </CheckAuth>
              }
            />
            <Route
              path="/bespoke"
              element={
                <CheckAuth>
                  <Bespoke columns={hotelColumns} />
                </CheckAuth>
              }
            />
            <Route
              path="/footer"
              element={
                <CheckAuth>
                  <FooterManagement columns={hotelColumns} />
                </CheckAuth>
              }
            />

            <Route
              path="/enquiry-form"
              element={
                <CheckAuth>
                  <EnquiryForm />
                </CheckAuth>
              }
            />

            <Route path="itinerary-trip">
              <Route
                index
                element={
                  <CheckAuth>
                    <ItineraryTableTrip />
                  </CheckAuth>
                }
              />

              <Route
                path="add"
                element={
                  <CheckAuth>
                    <TripItinerary />
                  </CheckAuth>
                }
              />

              <Route
                path="/itinerary-trip/:id"
                element={
                  <CheckAuth>
                    <EditTripItinerary />
                  </CheckAuth>
                }
              />

            </Route>

            <Route path="itinerary">
              <Route
                index
                element={
                  <CheckAuth>
                    <Itinerary />
                  </CheckAuth>
                }
              />

              <Route
                path="add"
                element={
                  <CheckAuth>
                    <TravelPackageForm />
                  </CheckAuth>
                }
              />

              <Route
                path="/itinerary/:id"
                element={
                  <CheckAuth>
                    <TravelEditPackageForm />
                  </CheckAuth>
                }
              />



            </Route>

            <Route path="rugs">
              <Route
                index
                element={
                  <CheckAuth>
                    <RugsForm />
                  </CheckAuth>
                }
              />

              <Route
                path="add"
                element={
                  <CheckAuth>
                    <ProductForm />
                  </CheckAuth>
                }
              />

              <Route
                path="/rugs/edit/:id"
                element={
                  <CheckAuth>
                    <RugsEditForm />
                  </CheckAuth>
                }
              />

            </Route>

            <Route path="order">
              <Route
                index
                element={
                  <CheckAuth>
                    <RugsOrderForm />
                  </CheckAuth>
                }
              />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </div >
  );
}

export default App;
