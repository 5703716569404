import React, { useEffect, useState } from 'react';
import Sidebar from '../sidebar/Sidebar';
import Navbar from '../navbar/Navbar';
import "./rugs.scss";
import Axios from '../../api';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

const RugsEditForm = () => {
    const { id } = useParams();
    const initialImageDetail = { url: '' };

    const [formData, setFormData] = useState({
        name: '',
        details: '',
        image: [initialImageDetail],
        price: '',
        slug: { current: '' },
        _updatedAt: new Date()
    });

    const handleChange = (e, index) => {
        const { name, value } = e.target;
        const updatedImageDetails = [...formData.image];
        if (name.startsWith('image-')) {
            const fieldName = name.split('-')[1];
            updatedImageDetails[index][fieldName] = value;
            setFormData({
                ...formData,
                image: updatedImageDetails,
            });
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    const handleSlugChange = (e) => {
        setFormData({
            ...formData,
            slug: { current: e.target.value }
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await Axios.put(`/api/products/${id}`, formData);
            if (response.status === 200) {
                toast.success('Rug details updated successfully', { position: toast.POSITION.TOP_RIGHT });
            } else {
                toast.error('Failed to update rug details.', { position: toast.POSITION.TOP_RIGHT });
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error('An error occurred while updating the rug details.', { position: toast.POSITION.TOP_RIGHT });
        }
    };

    const handleAddImageDetail = () => {
        setFormData({
            ...formData,
            image: [...formData.image, { ...initialImageDetail }]
        });
    };

    const handleRemoveImageDetail = (index) => {
        const updatedImageDetails = [...formData.image];
        updatedImageDetails.splice(index, 1);
        setFormData({
            ...formData,
            image: updatedImageDetails,
        });
    };

    useEffect(() => {
        async function fetchRugDetails() {
            try {
                const response = await Axios.get(`/api/products/${id}`);
                if (response.status === 200) {
                    const rugData = response.data;
                    setFormData(rugData);
                } else {
                    toast.error('Failed to fetch rug details.', { position: toast.POSITION.TOP_RIGHT });
                }
            } catch (error) {
                console.error('Error:', error);
                toast.error('An error occurred while fetching rug details.', { position: toast.POSITION.TOP_RIGHT });
            }
        }

        fetchRugDetails();
    }, [id]);

    return (
        <div className="list">
            <Sidebar />
            <div className="listContainer">
                <Navbar />
                <form onSubmit={handleSubmit} className="form-container">
                    <h2>Edit Rug Details</h2>
                    <div className="form-group">
                        <label>Name</label>
                        <input
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={(e) => handleChange(e)}
                            required
                        />

                        <label>Details</label>
                        <textarea
                            name="details"
                            value={formData.details}
                            onChange={(e) => handleChange(e)}
                            required
                        />

                        {formData.image.map((img, index) => (
                            <div key={index} className="image-details">
                                <h3>Image {index + 1}</h3>
                                <label>URL</label>
                                <input
                                    type="text"
                                    name={`image-url-${index}`}
                                    value={img.url}
                                    onChange={(e) => handleChange(e, index)}
                                    required
                                />

                                <button type="button" onClick={() => handleRemoveImageDetail(index)}>
                                    Remove Image
                                </button>
                            </div>
                        ))}

                        <button type="button" onClick={handleAddImageDetail}>
                            Add Image
                        </button>

                        <label>Price</label>
                        <input
                            type="number"
                            name="price"
                            value={formData.price}
                            onChange={(e) => handleChange(e)}
                            required
                        />

                        <label>Slug</label>
                        <input
                            type="text"
                            name="slug"
                            value={formData.slug.current}
                            onChange={(e) => handleSlugChange(e)}
                            required
                        />

                        <button type="submit">Submit</button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default RugsEditForm;
