import "./sidebar.scss";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { Link } from "react-router-dom";
import { DarkModeContext } from "../../context/darkModeContext";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import Inventory2Icon from "@mui/icons-material/Inventory2";

const Sidebar = () => {
  const { dispatch } = useContext(DarkModeContext);
  const AuthCont = useContext(AuthContext);

  const HandleLogout = () => {
    AuthCont.dispatch({ type: "LOGOUT" });
  };
  return (
    <div className="sidebar">
      {/* <div className="top"> */}
      <Link to="/" >
        <img src="LogoMidAsia.svg" alt="MidAsia logo" className="navbar-logo" />
      </Link>
      {/* </div> */}
      <div className="center">
        <ul>
          <p className="title">MAIN</p>
          <Link to="/" style={{ textDecoration: "none" }}>
            <li>
              <DashboardIcon className="icon" />
              <span>Dashboard</span>
            </li>
          </Link>
          <p className="title">LISTS</p>


          <Link to="/enquiry" style={{ textDecoration: "none" }}>
            <li>
              <Inventory2Icon className="icon" />
              <span>Enquiry</span>
            </li>
          </Link>

          <Link to="/bespoke" style={{ textDecoration: "none" }}>
            <li>
              <Inventory2Icon className="icon" />
              <span>Bespoke Form Details</span>
            </li>
          </Link>

          <Link to="/country" style={{ textDecoration: "none" }}>
            <li>
              <Inventory2Icon className="icon" />
              <span>By Destination</span>
            </li>
          </Link>

          <Link to="/itinerary-trip" style={{ textDecoration: "none" }}>
            <li>
              <Inventory2Icon className="icon" />
              <span>Small Group Boutique Expeditions</span>
            </li>
          </Link>

          <Link to="/itinerary" style={{ textDecoration: "none" }}>
            <li>
              <Inventory2Icon className="icon" />
              <span>Itinerary Table Package</span>
            </li>
          </Link>

          <Link to="/enquiry-form" style={{ textDecoration: "none" }}>
            <li>
              <Inventory2Icon className="icon" />
              <span>Enquiry Form</span>
            </li>
          </Link>
          <Link to="/rugs" style={{ textDecoration: "none" }}>
            <li>
              <Inventory2Icon className="icon" />
              <span>Rugs Clothes Form</span>
            </li>
          </Link>

          <Link to="/order" style={{ textDecoration: "none" }}>
            <li>
              <Inventory2Icon className="icon" />
              <span>Rugs Order</span>
            </li>
          </Link>

          <Link to="/footer" style={{ textDecoration: "none" }}>
            <li>
              <Inventory2Icon className="icon" />
              <span>Footer</span>
            </li>
          </Link>

          <p className="title">USER</p>
          <li>
            <AccountCircleOutlinedIcon className="icon" />
            <span>Profile</span>
          </li>
          <li onClick={HandleLogout}>
            <ExitToAppIcon className="icon" />
            <span>Logout</span>
          </li>
        </ul>
      </div>
      <div className="bottom">
        <div
          className="colorOption"
          onClick={() => dispatch({ type: "LIGHT" })}></div>
        <div
          className="colorOption"
          onClick={() => dispatch({ type: "DARK" })}></div>
      </div>
    </div>
  );
};

export default Sidebar;
