import React, { useEffect, useState } from "react";
import Axios from '../../api';

import "./rugs-form.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import RugsProductTable from "../../components/rugs/rugs-table";

const RugsForm = () => {
  const [rugList, setRugList] = useState([]);
  const fetchData = async () => {
    try {
      const response = await Axios.get('/api/products');
      setRugList(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="list">
      <Sidebar />
      <div className="listContainer">
        <Navbar />
        <RugsProductTable data={rugList} fetchData={fetchData} />
      </div>
    </div>
  );
};

export default RugsForm;
